<template>
  <el-row class="table">
  <el-button @click="getSolutionStatisticsByContest" type="primary" size="medium" class="tb-button">刷新排行榜</el-button>
  <el-table :data="dataList" class="tb-list" style="margin-top: 30px;">
    <el-table-column label="排名" align="center" width="100">
      <template slot-scope="scope">
                  <span>
                    <i v-if="scope.$index === 0" class="medal">
                      <img src="../../assets/images/gold.png">
                    </i>
                    <i v-else-if="scope.$index === 1" class="medal">
                      <img src="../../assets/images/silver.png" alt="">
                    </i>
                    <i v-else-if="scope.$index === 2" class="medal">
                      <img src="../../assets/images/cuprum.png" alt="">
                    </i>
                    <span style="margin-left: 20px;">第{{scope.$index + 1}}名</span>
                  </span>
      </template>
    </el-table-column>
    <el-table-column label="姓名" align="center">
      <template slot-scope="scope">
        <span>{{ scope.row.name }}</span>
      </template>
    </el-table-column>
    <el-table-column label="解决" align="center">
      <template slot-scope="scope">
        <span>{{ scope.row.compleNum }}</span>
      </template>
    </el-table-column>
<!--    <el-table-column label="最新提交时间" align="center">-->
<!--      <template slot-scope="scope">-->
<!--        <span>{{ scope.row.in_date | formatTime }}</span>-->
<!--      </template>-->
<!--    </el-table-column>-->
    <el-table-column label="罚时" align="center">
      <template slot-scope="scope">
        <span>{{ scope.row.total_cost }}</span>
      </template>
    </el-table-column>

    <el-table-column :label="item+''" v-for="(item, index) of problems" :key="index" align="center" v-if="census_control == 1">
      <template slot="header" slot-scope="scope">
        <el-tooltip class="item" effect="dark" :content="problems[index].problemName" placement="top">
          <span>{{index + 1}}</span>
        </el-tooltip>
      </template>
      <template slot-scope="scope">
      <span
        :style="'color'+':'+getStateColor(scope.row.everyProblem[index].rightFlag)"
        v-show="scope.row.everyProblem[index].errroNum != 0 && scope.row.everyProblem[index].rightFlag == 0"
      >错{{scope.row.everyProblem[index].errroNum}}次</span>
        <span
          :style="'color'+':'+getStateColor(scope.row.everyProblem[index].rightFlag)"
          v-show="scope.row.everyProblem[index].errroNum != 0 && scope.row.everyProblem[index].rightFlag == 1"
        >{{formatDuring(scope.row.everyProblem[index].time_cost)}}</span><span v-show="scope.row.everyProblem[index].errroNum != 0 && scope.row.everyProblem[index].rightFlag == 1" style="color: #CE433F;">(错{{scope.row.everyProblem[index].errroNum}}次)</span>
        <span
          :style="'color'+':'+getStateColor(scope.row.everyProblem[index].rightFlag)"
          v-show="scope.row.everyProblem[index].errroNum == 0 && scope.row.everyProblem[index].rightFlag == 1"
        >{{formatDuring(scope.row.everyProblem[index].time_cost)}}</span>
      </template>
    </el-table-column>
  </el-table>
  </el-row>
</template>

<script>
  import {getSolutionStatisticsByContest} from '@/api/senate/class'

  export default {
    data () {
      return {
        dataList: [],
        problems: [],
        contest_id: '',
        census_control: 1
      }
    },
    components: {

    },
    filters:{
      formatTime:function (value) {
        return value.substring(0,value.length - 2)
      }
    },
    created () {
        this.contest_id = this.$route.query.contest_id
        this.getSolutionStatisticsByContest()
    },
    methods: {
      formatDuring(mss) {
        let hours = Math.floor((mss/60/60));
        let minutes = Math.floor((mss % (60 * 60)) / 60);
        let seconds = Math.round(mss % 60);
        if (hours != 0) {
          return this.Appendzero(hours) + this.Appendzero(minutes) + this.SecondAppendzero(seconds) + "s";
        } else {
          if (minutes == 0) {
            return this.Appendzero(0) + this.Appendzero(0) + this.SecondAppendzero(seconds) + "s";
          } else if (hours == 0) {
            return this.Appendzero(0) + this.Appendzero(minutes) + this.SecondAppendzero(seconds) + "s";
          }
        }
      },
      Appendzero(obj) {
        if (obj < 10) return "0" + "" + obj + ":";
        else return obj + ":";
      },
      SecondAppendzero(obj) {
        if (obj < 10) return "0" + "" + obj;
        else return obj;
      },
      // 获取排行榜数据
      async getSolutionStatisticsByContest() {
        const res = await  getSolutionStatisticsByContest({
          contest_id: this.contest_id
        });
        if (res.state === "success") {
          this.census_control = parseInt(res.body.census_control)
          this.dataList = res.body.tbody
          this.problems = res.body.problems
        }
      },
      getStateColor(state) {
        switch (state) {
          case 0:
            return "#CE433F";
            break;
          case 1:
            return "#0CA51A";
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../style/table.scss";
  .medal {
    position: absolute;
    left: 10px;
    top: 5px;
  }
</style>
