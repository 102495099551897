var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c(
        "el-button",
        {
          staticClass: "tb-button",
          attrs: { type: "primary", size: "medium" },
          on: { click: _vm.getSolutionStatisticsByContest },
        },
        [_vm._v("刷新排行榜")]
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list",
          staticStyle: { "margin-top": "30px" },
          attrs: { data: _vm.dataList },
        },
        [
          _c("el-table-column", {
            attrs: { label: "排名", align: "center", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      scope.$index === 0
                        ? _c("i", { staticClass: "medal" }, [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/gold.png"),
                              },
                            }),
                          ])
                        : scope.$index === 1
                        ? _c("i", { staticClass: "medal" }, [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/silver.png"),
                                alt: "",
                              },
                            }),
                          ])
                        : scope.$index === 2
                        ? _c("i", { staticClass: "medal" }, [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/cuprum.png"),
                                alt: "",
                              },
                            }),
                          ])
                        : _vm._e(),
                      _c("span", { staticStyle: { "margin-left": "20px" } }, [
                        _vm._v("第" + _vm._s(scope.$index + 1) + "名"),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "姓名", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "解决", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.compleNum))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "罚时", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.total_cost))])]
                },
              },
            ]),
          }),
          _vm._l(_vm.problems, function (item, index) {
            return _vm.census_control == 1
              ? _c("el-table-column", {
                  key: index,
                  attrs: { label: item + "", align: "center" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.problems[index].problemName,
                                  placement: "top",
                                },
                              },
                              [_c("span", [_vm._v(_vm._s(index + 1))])]
                            ),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      scope.row.everyProblem[index].errroNum !=
                                        0 &&
                                      scope.row.everyProblem[index].rightFlag ==
                                        0,
                                    expression:
                                      "scope.row.everyProblem[index].errroNum != 0 && scope.row.everyProblem[index].rightFlag == 0",
                                  },
                                ],
                                style:
                                  "color" +
                                  ":" +
                                  _vm.getStateColor(
                                    scope.row.everyProblem[index].rightFlag
                                  ),
                              },
                              [
                                _vm._v(
                                  "错" +
                                    _vm._s(
                                      scope.row.everyProblem[index].errroNum
                                    ) +
                                    "次"
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      scope.row.everyProblem[index].errroNum !=
                                        0 &&
                                      scope.row.everyProblem[index].rightFlag ==
                                        1,
                                    expression:
                                      "scope.row.everyProblem[index].errroNum != 0 && scope.row.everyProblem[index].rightFlag == 1",
                                  },
                                ],
                                style:
                                  "color" +
                                  ":" +
                                  _vm.getStateColor(
                                    scope.row.everyProblem[index].rightFlag
                                  ),
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDuring(
                                      scope.row.everyProblem[index].time_cost
                                    )
                                  )
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      scope.row.everyProblem[index].errroNum !=
                                        0 &&
                                      scope.row.everyProblem[index].rightFlag ==
                                        1,
                                    expression:
                                      "scope.row.everyProblem[index].errroNum != 0 && scope.row.everyProblem[index].rightFlag == 1",
                                  },
                                ],
                                staticStyle: { color: "#CE433F" },
                              },
                              [
                                _vm._v(
                                  "(错" +
                                    _vm._s(
                                      scope.row.everyProblem[index].errroNum
                                    ) +
                                    "次)"
                                ),
                              ]
                            ),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      scope.row.everyProblem[index].errroNum ==
                                        0 &&
                                      scope.row.everyProblem[index].rightFlag ==
                                        1,
                                    expression:
                                      "scope.row.everyProblem[index].errroNum == 0 && scope.row.everyProblem[index].rightFlag == 1",
                                  },
                                ],
                                style:
                                  "color" +
                                  ":" +
                                  _vm.getStateColor(
                                    scope.row.everyProblem[index].rightFlag
                                  ),
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.formatDuring(
                                      scope.row.everyProblem[index].time_cost
                                    )
                                  )
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                })
              : _vm._e()
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }